@charset "UTF-8";
/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 24px;
}

.h2 {
  font-size: 20px;
}

.color-primary {
  color: #9c2f91;
}
.color-gray1 {
  color: #b3b3b3;
}
.color-gray2 {
  color: #cccccc;
}
.color-gray3 {
  color: #e6e6e6;
}
.color-gray4 {
  color: #f4f4f4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #9c2f91 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #9c2f91 !important;
  border-color: #9c2f91 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #4d4d4d;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #9c2f91 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #4d4d4d !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

a {
  color: #9c2f91 !important;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #0d0d0d;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #0d0d0d;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #2e4859;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #0d0d0d;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #2e4859;
}

.cpr {
  color: #9c2f91;
}

.cprt {
  color: #f6f9ff;
}

.bpr {
  background: #9c2f91;
}

.bprt {
  background: #f6f9ff;
}

.cse {
  color: #2e4859;
}

.bse {
  background: #2e4859;
}

.csu {
  color: #3cc13b;
}

.bsu {
  background: #3cc13b;
}

.csut {
  color: #d7f3d7;
}

.bsut {
  background-color: #d7f3d7;
}

.cwa {
  color: #f3bb1c;
}

.bwa {
  background: #f3bb1c;
}

.cwat {
  color: #fdf1d1;
}

.bwat {
  background: #fdf1d1;
}

.cda {
  color: #f03738;
}

.bda {
  background: #f03738;
}

.bdat {
  background: #fcd7d7;
}

.bcdat {
  background: #fcd7d7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0.5rem;
  border: none !important;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding: 10px 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 46px;
}
.bton--full {
  line-height: 46px;
  margin: 0;
  width: 100%;
  font-weight: 100;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  color: white;
  border: none;
  background: #9c2f91;
}
.bton--primary:hover {
  background-color: #cc27bc;
  color: white;
}
.bton--secondary {
  color: white;
  background-color: #2e4859;
  border-color: #2e4859;
}
.bton--secondary:hover {
  background-color: #ffae4e;
  border-color: #ffae4e;
  color: white;
}
.bton--ghost {
  border-radius: 4px;
  border: 1px solid #9c2f91 !important;
  color: #9c2f91;
  background: white;
  display: inline;
  text-align: center;
}
.bton--ghost:hover {
  background: linear-gradient(54.88deg, #856402 -15.56%, #e0a700 46.22%, #856402 103.33%);
  border-color: #cc27bc;
  color: white !important;
}
.bton--ghost--danger {
  border-color: #f03738;
  background: #fcd7d7;
  color: #f03738;
}
.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}
.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}
.bton--nacked {
  border: none;
  color: rgb(20, 20, 185);
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #4d4d4d;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #4d4d4d;
  display: inline-block;
}

.navBarContainer {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
}
.navBarContainer .headerWrapper {
  background: #9c2f91;
}
.navBarContainer .headerWrapper .topBar {
  display: flex;
  padding: 0.5rem;
}
.navBarContainer .headerWrapper .topBar-item {
  margin-right: 2rem;
}
.navBarContainer .headerWrapper .topBar-item__icon {
  margin-right: 0.5rem;
  color: white;
}
.navBarContainer .headerWrapper .topBar-item__icon svg {
  margin-bottom: 3px;
}
.navBarContainer .headerWrapper .topBar-item__info {
  margin-right: 0.5rem;
  color: white;
}
.navBarContainer .navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
}
.navBarContainer .navBar-brand {
  margin: 0;
  flex: 1;
}
.navBarContainer .navBar-brand img {
  width: 9rem;
}
.navBarContainer .navBar-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.navBarContainer .navBar-menu__items {
  padding: 1rem;
  cursor: pointer;
}
.navBarContainer .navBar-menu__items:hover {
  color: #9c2f91;
}

section[role=Footer] {
  margin-top: 8rem;
  background: #9c2f91;
}
section[role=Footer] .foooterInfo {
  margin-top: 3rem;
}
section[role=Footer] .foooterInfo img {
  width: 12rem;
}
section[role=Footer] .foooterInfo p {
  color: white;
  line-height: 180%;
  width: 100%;
  margin-top: 2rem;
}
@media all and (max-width: 1200px) {
  section[role=Footer] .foooterInfo p {
    line-height: 145%;
  }
}
section[role=Footer] .Links {
  color: white;
}
section[role=Footer] .Links-title {
  color: white;
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .Links-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .Links-box {
  margin-top: 2rem;
  padding: 0;
}
section[role=Footer] .Links-box-item {
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
section[role=Footer] .contact {
  color: white;
}
section[role=Footer] .contact-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .contact-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .contact-box {
  padding: 0;
}
section[role=Footer] .contact-box-item {
  margin-top: 2rem;
  display: flex;
}
@media all and (max-width: 576px) {
  section[role=Footer] .contact-box-item {
    margin-top: 1rem;
  }
}
section[role=Footer] .contact-box-item .iconHolder {
  margin-right: 1rem;
}
section[role=Footer] .contact-box-item .addHolder-value {
  font-size: 14px;
  font-weight: 100;
  color: white !important;
}
section[role=Footer] .socialMedia {
  color: white;
}
section[role=Footer] .socialMedia-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .socialMedia-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .socialMedia .socialWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
}
section[role=Footer] .socialMedia .socialWrapper-icons {
  cursor: pointer;
  margin-right: 1rem;
  border: 1px solid white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in 0.2s;
}
section[role=Footer] .socialMedia .socialWrapper-icons svg {
  color: white;
}
section[role=Footer] .socialMedia .socialWrapper-icons svg:hover {
  color: #9c2f91;
}
section[role=Footer] .socialMedia .socialWrapper-icons:hover {
  color: #9c2f91;
  background-color: white;
}
@media all and (max-width: 1200px) {
  section[role=Footer] {
    margin-top: 5rem;
  }
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}

.navbar {
  padding: 15px 0 !important;
  position: relative;
}
.navbar .active-link.active {
  color: #24bcaa !important;
  position: relative;
}
.navbar .active-link.active::before {
  content: "";
  position: absolute;
  background-color: #24bcaa;
  width: 30px;
  height: 1.5px;
  top: 33px;
}
.navbar .navbar-nav a {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #131d24 !important;
  margin-left: 50px;
}

.navbar-nav {
  position: relative;
}

.navdesc {
  height: 7rem;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
  overflow: hidden;
  margin-bottom: 0 !important;
}

.navBarContainer .navBar {
  position: relative !important;
}

.navbarDropdown {
  background-color: #2e4859;
  padding: 28px 31px;
  position: absolute;
  z-index: 99999;
  width: 80%;
}
.navbarDropdown__heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #9c2f91;
  border-bottom: 0.5px solid #e0e0e0;
  padding-bottom: 10px;
}
.navbarDropdown ul {
  padding-left: 0;
}
.navbarDropdown ul li {
  padding-bottom: 8px;
}
.navbarDropdown ul li a {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
}
.navbarDropdown__arrowup {
  position: absolute;
  right: 35%;
  width: 20px;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 15px solid #2e4859;
  top: -6%;
}

.resortstoggle {
  position: absolute;
  border: 1px solid rgb(230, 230, 230);
  left: 40%;
  top: 100%;
  z-index: 999;
  width: 55%;
  background-color: #f3f3f3;
}
.resortstoggle__leftnav {
  background-color: #fff;
  height: 410px;
}
.resortstoggle__leftnav ul {
  padding-left: 0;
}
.resortstoggle__leftnav ul li {
  padding: 12px 0 12px 25px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.resortstoggle__leftnav ul li:hover {
  color: white !important;
  background: linear-gradient(54.88deg, #856402 -15.56%, #e0a700 46.22%, #856402 103.33%);
}
.resortstoggle__leftnav ul li:hover a {
  color: white !important;
}
.resortstoggle__rightnav {
  padding-top: 20px;
  padding-right: 25px;
}
.resortstoggle__rightnav img {
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.resortstoggle__rightnav p {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #0d0d0d;
  margin-top: 18px;
  margin-bottom: 20px;
}

.navBar-menu__items {
  color: #000;
}

.smallnavbar {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.smallnavbar__image img {
  width: 22%;
}
.smallnavbar__openicon {
  margin-right: 20px;
  font-size: 28px;
  color: #9c2f91;
  border: 2px solid #9c2f91;
  padding: 4px;
  border-radius: 20%;
}
.smallnavbar__display {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 99999;
}
.smallnavbar__display img {
  position: absolute;
  left: 20px;
  top: 28px;
  width: 25%;
}
.smallnavbar__display--crossicon {
  position: absolute;
  right: 30px;
  top: 40px;
  color: #9c2f91;
  border: 2px solid #9c2f91;
  padding: 5px;
  border-radius: 50%;
  font-size: 25px;
}
.smallnavbar__display--mainul {
  margin-top: 100px;
  border-top: 2px solid #9c2f91;
  padding-top: 10px;
}
.smallnavbar__display--mainul li {
  padding: 10px 0;
}
.smallnavbar__display--mainul-subul li {
  padding: 5px 0;
  position: relative;
}
.smallnavbar__display--mainul-subul li a {
  height: 1px;
  color: #000 !important;
}
.smallnavbar__display--mainul-subul li a::after {
  content: "";
  background-color: #9c2f91;
  height: 2px;
  width: 35px;
  position: absolute;
  top: 25px;
  left: 0;
}

@media all and (max-width: 768px) {
  .smallnavbar {
    display: flex !important;
  }
}
@media all and (max-width: 768px) {
  .largenavbar {
    display: none !important;
  }
}
@media (max-width: 425px) {
  .smallnavbar__image img {
    width: 32%;
  }
}
.loginButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f5f5f5;
  margin-left: 2rem;
  padding: 0.5rem !important;
  border-radius: 6px;
}

.footer {
  background-color: #131d24;
  padding: 70px 0 20px 0;
}
.footer__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #9c2f91;
}
.footer__title--solution {
  -moz-column-count: 2;
       column-count: 2;
}
.footer ul {
  padding-left: 0;
}
.footer ul li {
  color: #ffffff;
  padding-bottom: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
}
.footer ul li a {
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  padding-bottom: 10px;
}
.footer__icon {
  color: #000;
  font-size: 30px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 5px;
  margin-right: 10px;
}
.footer__copyright {
  border-top: 0.8px solid #24BCAA;
}
.footer__copyright p {
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-align: center;
  padding-top: 25px;
}

.carouselWrapper {
  margin-top: 2rem;
  position: relative;
  height: 72vh;
}
.carouselWrapper-image {
  height: 30rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  .carouselWrapper-image {
    height: 25rem;
  }
}
@media all and (max-width: 576px) {
  .carouselWrapper-image {
    height: 15rem;
  }
}
.carouselWrapper .reservationform {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 40px 20px;
  width: 75%;
  position: absolute;
  bottom: -80px;
  z-index: 99999;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 20px;
}
@media all and (max-width: 992px) {
  .carouselWrapper .reservationform {
    width: 100% !important;
    position: initial;
    margin-top: 20px;
    padding: 20px;
  }
}
@media all and (max-width: 1200px) {
  .carouselWrapper .reservationform {
    width: 95%;
  }
}
@media all and (max-width: 992px) {
  .carouselWrapper .reservationform {
    transform: translate(0, 0);
  }
}
.carouselWrapper .reservationform input,
.carouselWrapper .reservationform .form-select {
  border: none;
  outline: none;
}
.carouselWrapper .reservationform input:focus,
.carouselWrapper .reservationform .form-select:focus {
  box-shadow: none !important;
}
@media all and (max-width: 768px) {
  .carouselWrapper .reservationform input,
  .carouselWrapper .reservationform .form-select {
    margin-bottom: 15px;
  }
}
.carouselWrapper .reservationform label {
  position: relative;
  margin-left: 10px;
  font-weight: 600;
}
.carouselWrapper .reservationform label::after {
  content: "";
  width: 165px;
  height: 0.5px;
  background-color: #0d0d0d;
  position: absolute;
  top: 68px;
  left: 5px;
}
@media all and (max-width: 992px) {
  .carouselWrapper .reservationform label::after {
    width: 630px;
  }
}
@media all and (max-width: 768px) {
  .carouselWrapper .reservationform label::after {
    width: 450px;
  }
}
@media all and (max-width: 576px) {
  .carouselWrapper .reservationform label::after {
    width: 450px;
  }
}
@media all and (max-width: 480px) {
  .carouselWrapper .reservationform label::after {
    width: 200px;
  }
}

.introContainer {
  background: #f9f6ee;
  margin: 3rem 0;
}
@media all and (max-width: 768px) {
  .introContainer {
    margin-top: 20rem;
  }
}
.introContainer .introHolder {
  padding: 3.5rem;
}
@media all and (max-width: 768px) {
  .introContainer .introHolder {
    padding: 3.5rem 0;
  }
}
.introContainer .introHolder-title {
  text-align: center;
  color: #4d4d4d;
}
.introContainer .introHolder-slogan {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  padding: 1rem;
  background: linear-gradient(54.88deg, #856402 -15.56%, #e0a700 46.22%, #856402 103.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.introContainer .introHolder-desc {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  color: #0d0d0d;
}
@media all and (max-width: 768px) {
  .introContainer .introHolder-desc {
    width: 100%;
  }
}

.line {
  width: 4rem;
  background: #4d4d4d;
  display: inline-block;
  border-bottom: 1px solid #4d4d4d;
  margin: 0 0.5rem 0.3rem 0.5rem;
}

.titleBlockHolder {
  padding: 3rem 0;
}
.titleBlockHolder-preTitle {
  text-align: center;
  text-transform: uppercase;
}
.titleBlockHolder-title {
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
}
.titleBlockHolder-desc {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
@media all and (max-width: 768px) {
  .titleBlockHolder-desc {
    width: 100%;
  }
}

.branchCard {
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
  margin-bottom: 2rem;
}
.branchCard-image img {
  height: 15rem;
  background: #000;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.branchCard-title {
  text-align: center;
}
.branchCard-paragraph {
  text-align: justify;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
  height: 7rem;
  overflow: hidden;
}

.roomsWrapper {
  padding: 2rem;
  margin: 3rem 0;
  background: #f9f6ee;
}
@media all and (max-width: 768px) {
  .roomsWrapper {
    padding: 0 2rem;
  }
}
.roomsWrapper .roomCard {
  background: white;
}
.roomsWrapper .roomCard-image img {
  height: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.roomsWrapper .roomCard-title {
  text-align: center;
}
.roomsWrapper .roomCard-attributes {
  text-align: center;
  padding: 1rem;
}

.animitiesBox {
  height: 100%;
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem 0;
  text-align: center;
  gap: 16px;
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
}
.animitiesBox svg {
  border: 1px solid rgb(230, 230, 230);
  padding: 0.5rem;
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #9c2f91;
}

.testiWrapper {
  border: 12px solid rgb(221, 221, 221);
  padding: 3rem;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3rem;
}
@media all and (max-width: 768px) {
  .testiWrapper {
    width: 100%;
  }
}
.testiWrapper-desc {
  text-align: center;
  line-height: 200%;
  font-style: italic;
  color: #4d4d4d;
}
.testiWrapper-name {
  text-align: center;
}

.modal-dialog {
  max-width: 700px !important;
}
.modal-dialog .modal-content {
  padding: 20px;
  border: none;
}
.modal-dialog .modal-footer {
  border-top: transparent;
}

.modal-header {
  border-bottom: transparent !important;
}
.modal-header .modal-title {
  text-transform: uppercase;
}

.personal-info p {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #0d0d0d;
  text-transform: capitalize;
}

.modal-header .modal-title {
  text-transform: uppercase;
  margin-left: 170px;
  font-weight: 600;
}

.testominial-Wrapper {
  padding: 2rem;
  margin: 3rem 0;
  background: #f9f6ee;
}

.loadingHolder {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: grid;
  place-items: center;
}
.loadingHolder img {
  height: 4rem;
}

.contact__us {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0d0d0d;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-top: 70px;
}
.contact__us::before {
  content: "";
  position: absolute;
  left: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .contact__us::before {
    left: 35%;
  }
}
@media all and (max-width: 480px) {
  .contact__us::before {
    left: 30%;
  }
}
.contact__us::after {
  content: "";
  position: absolute;
  right: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .contact__us::after {
    right: 35%;
  }
}
@media all and (max-width: 480px) {
  .contact__us::after {
    right: 30%;
  }
}
.contact h1 {
  margin-top: 18px;
  text-transform: uppercase;
  text-align: center;
  font-size: 50px !important;
  font-weight: 400 !important;
}
@media all and (max-width: 480px) {
  .contact h1 {
    font-size: 35px !important;
  }
}
.contact__desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #0d0d0d;
  margin-top: 35px;
}
.contact__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  padding: 15px 45px;
  margin-top: 60px;
}
@media all and (max-width: 768px) {
  .contact__detail {
    display: block;
  }
}
.contact__detail .icon {
  color: #9c2f91;
}
.contact__detail span {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #0d0d0d;
}
.contact__map {
  margin: 30px 0 60px 0;
}
.contact__map iframe {
  width: 100%;
  height: 400px;
}

.offer {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0d0d0d;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.offer::before {
  content: "";
  position: absolute;
  left: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .offer::before {
    left: 38%;
  }
}
@media all and (max-width: 480px) {
  .offer::before {
    left: 30%;
  }
}
.offer::after {
  content: "";
  position: absolute;
  right: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .offer::after {
    right: 38%;
  }
}
@media all and (max-width: 480px) {
  .offer::after {
    right: 30%;
  }
}

.offer-page .component_bg {
  background-color: #fff !important;
}

.gallery__us {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0d0d0d;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-top: 70px;
}
.gallery__us::before {
  content: "";
  position: absolute;
  left: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .gallery__us::before {
    left: 35%;
  }
}
@media all and (max-width: 480px) {
  .gallery__us::before {
    left: 30%;
  }
}
.gallery__us::after {
  content: "";
  position: absolute;
  right: 43%;
  height: 1px;
  width: 27px;
  background-color: #0d0d0d;
  top: 9px;
}
@media all and (max-width: 768px) {
  .gallery__us::after {
    right: 35%;
  }
}
@media all and (max-width: 480px) {
  .gallery__us::after {
    right: 30%;
  }
}
.gallery h1 {
  margin-top: 18px;
  text-transform: uppercase;
  text-align: center;
  font-size: 50px !important;
  font-weight: 400 !important;
}
.gallery__desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #0d0d0d;
  width: 75%;
  margin: 35px auto 0 auto;
}
.gallery__photos {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.gallery__photos--inner {
  flex: 25%;
  padding: 0 4px;
}
@media all and (max-width: 768px) {
  .gallery__photos--inner {
    flex: 50%;
  }
}
.gallery__photos--inner img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.rooms .roomsWrapper {
  background-color: transparent;
  margin: 0 !important;
}

.roomdesc {
  margin-bottom: 2rem;
}
.roomdesc h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 50px !important;
  font-weight: 400 !important;
}
.roomdesc__desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #0d0d0d;
  margin-top: 35px;
}
.roomdesc__description {
  margin-top: 20px;
}
.roomdesc__description--leftbox {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
  padding: 40px;
}
.roomdesc__description--leftbox--heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 768px) {
  .roomdesc__description--leftbox--heading {
    display: block;
  }
}
.roomdesc__description--leftbox--heading p {
  font-weight: 5 0;
  font-size: 24px;
  line-height: 36px;
  color: #0d0d0d;
}
.roomdesc__description--leftbox--about p {
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #4a4a4a;
}
.roomdesc__description--leftbox--facility p {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #0d0d0d;
}
.roomdesc__description--leftbox--facility ul {
  padding-left: 10px;
  -moz-column-count: 2;
       column-count: 2;
}
.roomdesc__description--leftbox--facility ul li {
  list-style: disc;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4a4a4a;
  padding: 7px 0;
}
.roomdesc__description--reservation {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
  padding: 40px;
}
.roomdesc__description--reservation p {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #0d0d0d;
  text-align: center;
}
.roomdesc__description--reservation label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
}
.roomdesc__morerooms {
  margin-top: 100px;
}
.roomdesc__morerooms--title {
  font-weight: 400;
  font-size: 42px;
  line-height: 55px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #0d0d0d;
  margin-bottom: 60px;
  text-transform: uppercase;
}
.roomdesc__morerooms .roomCard-image img {
  height: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.roomdesc__morerooms h2 {
  text-align: center;
  text-transform: capitalize;
  margin-top: 40px;
}
.roomdesc__morerooms .roomCard-attributes {
  text-align: center;
  margin: 1rem 0;
}
.roomdesc__morerooms a {
  text-align: center;
}

.book-nowbtn {
  padding: 5px 28px !important;
  margin-top: 40px !important;
  width: 100% !important;
}

.branch {
  margin: 30px 0 50px 0;
}
.branch__banner img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}

.contact__slider {
  margin-top: 60px;
}
.contact__slider img {
  width: 98%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 480px) {
  .contact__slider img {
    width: 80%;
    height: 250px;
    -o-object-fit: cover;
       object-fit: cover;
    margin: 0 auto;
  }
}
.contact__slider .slick-prev:before {
  content: "←" !important;
  color: #000 !important;
  background-color: transparent !important;
  font-family: none;
  font-size: 24px;
}
@media all and (max-width: 480px) {
  .contact__slider .slick-prev:before {
    left: 1px;
  }
}
.contact__slider .slick-next:before {
  content: "→" !important;
  color: #000 !important;
  background-color: transparent !important;
  font-family: none;
  font-size: 24px;
}
@media all and (max-width: 480px) {
  .contact__slider .slick-next:before {
    right: 1px;
  }
}

.branchestab {
  margin-top: 60px;
}
.branchestab .nav-tabs {
  border-bottom: none;
}
.branchestab .nav-tabs .nav-link:hover {
  border: none I !important;
}
.branchestab .nav-tabs .nav-link.active {
  color: #fff !important;
  background: linear-gradient(54.88deg, #856402 -15.56%, #e0a700 46.22%, #856402 103.33%);
  border-color: none;
}
.branchestab .nav-tabs .nav-link {
  border-color: #856402 !important;
  border-radius: 80px;
  color: #856402 !important;
  margin: 0 15px 0 15px;
  padding: 10px 30px;
}
.branchestab__dining img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.branchestab__dining--desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #4a4a4a;
  width: 80%;
  margin: 60px auto;
}
.branchestab__dining--left {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
}
.branchestab__dining--left ul {
  list-style: disc;
  padding-left: 46px;
  padding-bottom: 20px;
}
.branchestab__dining--left ul li {
  padding: 5px 0;
  color: #4a4a4a;
  font-size: 16px;
}
.branchestab__dining--left p {
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #0d0d0d;
  border-bottom: 0.8px solid #c2c2c2;
  padding: 11px 30px;
}
.branchestab__dining--right {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
}
.branchestab__dining--right ul {
  -moz-column-count: 3;
       column-count: 3;
  padding-left: 46px;
  padding-bottom: 20px;
}
@media all and (max-width: 768px) {
  .branchestab__dining--right ul {
    -moz-column-count: 2;
         column-count: 2;
  }
}
.branchestab__dining--right ul li {
  list-style: disc;
  padding: 5px 0;
  color: #4a4a4a;
  font-size: 16px;
}
.branchestab__dining--right p {
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #0d0d0d;
  border-bottom: 0.8px solid #c2c2c2;
  padding: 11px 30px;
}
.branchestab__banquethall .component_bg {
  background-color: #fff !important;
}
.branchestab__reviews {
  width: 97%;
  background-color: #f3f3f3;
  padding: 50px 70px;
  position: relative;
}
.branchestab__reviews--icon {
  color: #d0d0d0;
  font-size: 25px;
  position: absolute;
  left: 20px;
  top: 35px;
}
.branchestab__reviews--review {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
}
.branchestab__reviews h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #0d0d0d;
  margin-top: 30px;
  margin-bottom: 0;
}
.branchestab__reviews--post {
  text-align: center;
  font-weight: 500;
}
.branchestab__photo img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}

.roomdesc img {
  width: 100%;
  height: 515px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  .roomdesc img {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .nav-item {
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .slick-prev {
    left: 1px !important;
  }
  .slick-next {
    right: 5px !important;
  }
}
.reservationwrapper {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
  width: 60%;
  margin: 80px auto;
  padding: 40px 20px;
}
@media all and (max-width: 768px) {
  .reservationwrapper {
    width: 90%;
  }
}
.reservationwrapper h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #9c2f91;
  font-size: 30px;
  position: relative;
}

.bookingWrapper {
  background: #f6f6f6;
  width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  padding: 1rem;
}
.bookingWrapper h5 {
  margin-bottom: 1rem;
}
.bookingWrapper-roomDetails {
  background: #fff;
  padding: 0.5rem;
  border-radius: 4px;
}
.bookingWrapper-roomDetails-info {
  font-size: 14px;
  color: rgb(126, 126, 126);
  line-height: 170%;
}
.bookingWrapper-roomDetails-info .roomSelectBox {
  width: 50%;
}
.bookingWrapper-roomDetails-info .roomSelectBox label {
  font-size: 16px;
  color: #0d0d0d;
  margin-top: 1rem;
}
.bookingWrapper-customerDetials {
  margin-top: 2rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 4px;
}
.bookingWrapper-billDetails {
  margin-top: 2rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 4px;
}

.paymentWrapper {
  background: #f6f6f6;
  width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  padding: 1rem;
}
.paymentWrapper h5 {
  margin-bottom: 1rem;
}
.paymentWrapper .paymentBox {
  margin-top: 1rem;
  padding: 0;
  display: flex;
}
.paymentWrapper .paymentBox-items {
  background: #fff;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 8px;
}
.paymentWrapper .paymentBox .activePayment {
  border: 2px solid #9c2f91;
}

.bookingSuccess {
  text-align: center;
}
.bookingSuccess-icon {
  font-size: 4rem;
  color: green;
}
.bookingSuccess-message {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
}
.bookingSuccess-desc {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 400;
}

.loginWrapper {
  background: #f6f6f6;
  margin: 2rem auto;
  border-radius: 8pxd;
  width: 500px;
  padding: 2rem;
}
.loginWrapper h3 {
  text-align: center;
  margin-bottom: 1rem;
}
.loginWrapper .alreadyLogin {
  margin-left: 0.5rem;
  margin-top: 1rem;
  font-size: 14px;
}
.loginWrapper .alreadyLogin span {
  text-decoration: underline;
  color: rgb(0, 0, 201);
  font-size: 16px;
  cursor: pointer;
}

.accountWrapper {
  width: 50%;
  margin: 0 auto;
}
.accountWrapper .myAccount {
  margin: 2rem 0;
}
.accountWrapper .myAccount h5 {
  text-align: center;
}
.accountWrapper .myBookings {
  margin: 2rem 0;
}
.accountWrapper .myBookings h5 {
  text-align: center;
}
.accountWrapper .myBookings ul {
  padding: 0;
}
.accountWrapper .myBookings ul .bookingCard {
  display: flex;
  background: #f7f7f7;
  margin-top: 1rem;
}
.accountWrapper .myBookings ul .bookingCard-image {
  margin: 0;
}
.accountWrapper .myBookings ul .bookingCard-image img {
  width: 10rem;
}
.accountWrapper .myBookings ul .bookingCard-info {
  padding: 1rem;
}
.accountWrapper .myBookings ul .bookingCard-info-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.accountWrapper .myBookings ul .bookingCard-info-title span {
  background: #ffde72;
  font-weight: 400;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
}
.accountWrapper .myBookings ul .bookingCard-info-dates {
  font-size: 15px;
  margin-bottom: 0.5rem;
  color: #888888;
}
.accountWrapper .myBookings ul .bookingCard-info-rooms {
  font-size: 15px;
  margin-bottom: 0.5rem;
  color: #888888;
}
.accountWrapper .myBookings ul .bookingCard-info-price {
  font-size: 18px;
  font-weight: 500;
}

.resultsWrapper-title h5 {
  margin-top: 2rem;
  text-align: center;
}
.resultsWrapper ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
}
.resultsWrapper ul .resultsCard {
  display: flex;
  background: #f7f7f7;
  margin-top: 1rem;
}
.resultsWrapper ul .resultsCard-image {
  margin: 0;
}
.resultsWrapper ul .resultsCard-image img {
  width: 18rem;
}
.resultsWrapper ul .resultsCard-info {
  padding: 1rem;
}
.resultsWrapper ul .resultsCard-info-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.resultsWrapper ul .resultsCard-info-title span {
  background: #ffde72;
  font-weight: 400;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
}
.resultsWrapper ul .resultsCard-info-occupancy {
  font-size: 15px;
  margin-bottom: 0.5rem;
  color: #e69500;
}
.resultsWrapper ul .resultsCard-info-dates {
  font-size: 15px;
  margin-bottom: 0.5rem;
  color: #888888;
}
.resultsWrapper ul .resultsCard-info-rooms {
  font-size: 15px;
  margin-bottom: 0.5rem;
  color: #00be20;
}
.resultsWrapper ul .resultsCard-info-price {
  font-size: 18px;
  font-weight: 500;
}/*# sourceMappingURL=App.css.map */