.gallery {
  &__us {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0d0d0d;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-top: 70px;
    &::before {
      content: '';
      position: absolute;
      left: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md) {
        left: 35%;
      }
      @include responsive-for(xs) {
        left: 30%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md) {
        right: 35%;
      }
      @include responsive-for(xs) {
        right: 30%;
      }
    }
  }
  h1 {
    margin-top: 18px;
    text-transform: uppercase;
    text-align: center;
    font-size: 50px !important;
    font-weight: 400 !important;
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #0d0d0d;
    width: 75%;
    margin: 35px auto 0 auto;
  }
  &__photos {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    &--inner {
      flex: 25%;
      padding: 0 4px;
      @include responsive-for(md) {
        flex: 50%;
      }
      img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
