.loginWrapper {
  background: #f6f6f6;
  margin: 2rem auto;
  border-radius: 8pxd;
  width: 500px;
  padding: 2rem;
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .alreadyLogin {
    margin-left: 0.5rem;
    margin-top: 1rem;
    font-size: 14px;
    span {
      text-decoration: underline;
      color: rgb(0, 0, 201);
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.accountWrapper {
  width: 50%;
  margin: 0 auto;
  .myAccount {
    margin: 2rem 0;
    h5 {
      text-align: center;
    }
  }

  .myBookings {
    margin: 2rem 0;
    h5 {
      text-align: center;
    }
    ul {
      padding: 0;

      .bookingCard {
        display: flex;
        background: #f7f7f7;
        margin-top: 1rem;

        &-image {
          margin: 0;
          img {
            width: 10rem;
          }
        }

        &-info {
          padding: 1rem;
          &-title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0.5rem;
            span {
              background: #ffde72;
              font-weight: 400;
              font-size: 14px;
              padding: 0.2rem 0.5rem;
              border-radius: 8px;
            }
          }

          &-dates {
            font-size: 15px;
            margin-bottom: 0.5rem;
            color: #888888;
          }
          &-rooms {
            font-size: 15px;
            margin-bottom: 0.5rem;
            color: #888888;
          }
          &-price {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

//results page

.resultsWrapper {
  &-title {
    h5 {
      margin-top: 2rem;
      text-align: center;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    .resultsCard {
      display: flex;
      background: #f7f7f7;
      margin-top: 1rem;

      &-image {
        margin: 0;
        img {
          width: 18rem;
        }
      }

      &-info {
        padding: 1rem;
        &-title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0.5rem;
          span {
            background: #ffde72;
            font-weight: 400;
            font-size: 14px;
            padding: 0.2rem 0.5rem;
            border-radius: 8px;
          }
        }

        &-occupancy {
          font-size: 15px;
          margin-bottom: 0.5rem;
          color: #e69500;
        }

        &-dates {
          font-size: 15px;
          margin-bottom: 0.5rem;
          color: #888888;
        }
        &-rooms {
          font-size: 15px;
          margin-bottom: 0.5rem;
          color: #00be20;
        }
        &-price {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
