.roomdesc {
  margin-bottom: 2rem;
  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 50px !important;
    font-weight: 400 !important;
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #0d0d0d;
    margin-top: 35px;
  }
  &__description {
    margin-top: 20px;
    &--leftbox {
      box-shadow: (0px 0px 16px rgba(211, 211, 211, 0.4));
      padding: 40px;
    }
    &--leftbox--heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include responsive-for(md) {
        display: block;
      }
      p {
        font-weight: 5 00;
        font-size: 24px;
        line-height: 36px;
        color: #0d0d0d;
      }
    }
    &--leftbox--about {
      p {
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #4a4a4a;
      }
    }
    &--leftbox--facility {
      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #0d0d0d;
      }
      ul {
        padding-left: 10px;
        column-count: 2;
        li {
          list-style: disc;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #4a4a4a;
          padding: 7px 0;
        }
      }
    }
    &--reservation {
      box-shadow: (0px 0px 16px rgba(211, 211, 211, 0.4));
      padding: 40px;
      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #0d0d0d;
        text-align: center;
      }
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
      }
    }
  }
  &__morerooms {
    margin-top: 100px;
    &--title {
      font-weight: 400;
      font-size: 42px;
      line-height: 55px;
      letter-spacing: 0.04em;
      text-align: center;
      color: #0d0d0d;
      margin-bottom: 60px;
      text-transform: uppercase;
    }
    .roomCard-image img {
      height: 20rem;
      object-fit: cover;
      width: 100%;
    }
    h2 {
      text-align: center;
      text-transform: capitalize;
      margin-top: 40px;
    }
    .roomCard-attributes {
      text-align: center;
      margin: 1rem 0;
    }
    a {
      text-align: center;
    }
  }
}
.book-nowbtn {
  padding: 5px 28px !important;
  margin-top: 40px !important;
  width: 100% !important;
}
