.contact {
  &__us {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0d0d0d;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-top: 70px;
    &::before {
      content: '';
      position: absolute;
      left: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md) {
        left: 35%;
      }
      @include responsive-for(xs) {
        left: 30%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md) {
        right: 35%;
      }
      @include responsive-for(xs) {
        right: 30%;
      }
    }
  }
  h1 {
    margin-top: 18px;
    text-transform: uppercase;
    text-align: center;
    font-size: 50px !important;
    font-weight: 400 !important;
    @include responsive-for(xs) {
      font-size: 35px !important;
    }
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #0d0d0d;
    margin-top: 35px;
  }
  &__detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    padding: 15px 45px;
    margin-top: 60px;
    @include responsive-for(md) {
      display: block;
    }
    .icon {
      color: color(primary, base);
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #0d0d0d;
    }
  }
  &__map {
    margin: 30px 0 60px 0;
    iframe {
      width: 100%;
      height: 400px;
    }
  }
}
