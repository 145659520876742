.reservationwrapper {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
  width: 60%;
  margin: 80px auto;
  padding: 40px 20px;
  @include responsive-for(md) {
    width: 90%;
  }
  h1 {
    text-align: center;
    margin-bottom: 40px;
    color: color(primary, base);
    font-size: 30px;
    position: relative;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 80px;
    //   height: 2px;
    //   top: 32px;
    //   left: 355px;
    //   background-color: color(primary, base);
    // }
  }
}
