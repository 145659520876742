.offer{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0d0d0d;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md){
        left: 38%;
      }
      @include responsive-for(xs){
        left: 30%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 43%;
      height: 1px;
      width: 27px;
      background-color: #0d0d0d;
      top: 9px;
      @include responsive-for(md){
        right: 38%;
      }
      @include responsive-for(xs){
        right: 30%;
      }
    }
}
.offer-page{
    .component_bg{
        background-color: #fff !important;
    }
}