.navBarContainer {
  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);

  .headerWrapper {
    background: color(primary, base);

    .topBar {
      display: flex;
      padding: 0.5rem;

      &-item {
        margin-right: 2rem;

        &__icon {
          margin-right: 0.5rem;
          color: white;

          svg {
            margin-bottom: 3px;
          }
        }
        &__info {
          margin-right: 0.5rem;
          color: white;
        }
      }
    }
  }

  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;

    &-brand {
      margin: 0;
      flex: 1;
      img {
        width: 9rem;
      }
    }

    &-menu {
      @include flex-center;
      margin: 0;
      &__items {
        padding: 1rem;
        cursor: pointer;

        &:hover {
          color: color(primary, base);
        }
      }
    }
  }
}
