.carouselWrapper {
  margin-top: 2rem;
  position: relative;
  height: 72vh;

  &-image {
    height: 30rem;
    width: 100%;
    object-fit: cover;
    @include responsive-for(md) {
      height: 25rem;
    }
    @include responsive-for(sm) {
      height: 15rem;
    }
  }
  .reservationform {
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    padding: 40px 20px;
    width: 75%;
    position: absolute;
    bottom: -80px;
    z-index: 99999;
    background-color: #fff;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 20px;
    @include responsive-for(lg) {
      width: 100% !important;
      position: initial;
      margin-top: 20px;
      padding: 20px;
    }
    @include responsive-for(xlg) {
      width: 95%;
    }
    @include responsive-for(lg) {
      transform: translate(0, 0);
    }

    input,
    .form-select {
      border: none;
      outline: none;
      &:focus {
        box-shadow: none !important;
      }
      @include responsive-for(md) {
        margin-bottom: 15px;
      }
    }
    label {
      position: relative;
      margin-left: 10px;
      font-weight: 600;
      &::after {
        content: '';
        width: 165px;
        height: 0.5px;
        background-color: #0d0d0d;
        position: absolute;
        top: 68px;
        left: 5px;
        @include responsive-for(lg) {
          width: 630px;
        }
        @include responsive-for(md) {
          width: 450px;
        }
        @include responsive-for(sm) {
          width: 450px;
        }
        @include responsive-for(xs) {
          width: 200px;
        }
      }
    }
  }
}
.introContainer {
  background: color(primary, tint2);
  margin: 3rem 0;
  @include responsive-for(md) {
    margin-top: 20rem;
  }

  .introHolder {
    padding: 3.5rem;
    @include responsive-for(md) {
      padding: 3.5rem 0;
    }
    &-title {
      text-align: center;
      color: color(text-light);
    }
    &-slogan {
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      padding: 1rem;
      background: linear-gradient(
        54.88deg,
        #856402 -15.56%,
        #e0a700 46.22%,
        #856402 103.33%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
    }
    &-desc {
      text-align: center;
      width: 60%;
      margin: 0 auto;
      color: color(text-dark);
      @include responsive-for(md) {
        width: 100%;
      }
    }
  }
}

.line {
  width: 4rem;
  background: color(text-light);
  display: inline-block;
  border-bottom: 1px solid color(text-light);
  margin: 0 0.5rem 0.3rem 0.5rem;
}

.titleBlockHolder {
  padding: 3rem 0;

  &-preTitle {
    text-align: center;
    text-transform: uppercase;
  }

  &-title {
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
  }
  &-desc {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    @include responsive-for(md) {
      width: 100%;
    }
  }
}
//branch cards here

.branchCard {
  border: $border;
  padding: 1rem;
  margin-bottom: 2rem;

  &-image {
    img {
      height: 15rem;
      background: #000;
      width: 100%;
      object-fit: cover;
    }
  }

  &-title {
    text-align: center;
  }

  &-paragraph {
    text-align: justify;
    @include truncate();
    height: 7rem;
    overflow: hidden;
  }
}

.roomsWrapper {
  padding: 2rem;
  margin: 3rem 0;
  background: color(primary, tint2);
  @include responsive-for(md) {
    padding: 0 2rem;
  }

  .roomCard {
    background: white;
    &-image {
      img {
        height: 20rem;
        object-fit: cover;
        width: 100%;
      }
    }
    &-title {
      text-align: center;
    }
    &-attributes {
      text-align: center;
      padding: 1rem;
    }
  }
}

.animitiesBox {
  height: 100%;
  border: $border;
  padding: 1rem 0;
  text-align: center;
  gap: 16px;

  box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);

  svg {
    border: $border;
    padding: 0.5rem;
    font-size: 4rem;
    margin-bottom: 1rem;
    // color: color(text-light);
    color: color(primary, base);
  }

  // &-title {
  // }
}

.testiWrapper {
  border: 12px solid rgb(221, 221, 221);
  padding: 3rem;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3rem;
  @include responsive-for(md) {
    width: 100%;
  }

  // &-icon {
  // }
  &-desc {
    text-align: center;
    line-height: 200%;
    font-style: italic;
    color: color(text-light);
  }
  &-name {
    text-align: center;
  }
}
.modal-dialog {
  max-width: 700px !important;
  .modal-content {
    padding: 20px;
    border: none;
  }
  .modal-footer {
    border-top: transparent;
  }
}
.modal-header {
  border-bottom: transparent !important;
  .modal-title {
    text-transform: uppercase;
  }
}
.personal-info p {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #0d0d0d;
  text-transform: capitalize;
}
.modal-header .modal-title {
  text-transform: uppercase;
  margin-left: 170px;
  font-weight: 600;
}
.testominial-Wrapper {
  padding: 2rem;
  margin: 3rem 0;
  background: #f9f6ee;
}

.loadingHolder {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  display: grid;
  place-items: center;
  img {
    height: 4rem;
  }
}
