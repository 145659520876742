.branch {
  margin: 30px 0 50px 0;
  &__banner {
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}
.contact {
  &__slider {
    margin-top: 60px;
    img {
      width: 98%;
      height: 300px;
      object-fit: cover;
      @include responsive-for(xs) {
        width: 80%;
        height: 250px;
        object-fit: cover;
        margin: 0 auto;
      }
    }
    .slick-prev:before {
      content: '\2190' !important;
      color: #000 !important;
      background-color: transparent !important;
      font-family: none;
      font-size: 24px;
      @include responsive-for(xs) {
        left: 1px;
      }
    }
    .slick-next:before {
      content: '\2192' !important;
      color: #000 !important;
      background-color: transparent !important;
      font-family: none;
      font-size: 24px;
      @include responsive-for(xs) {
        right: 1px;
      }
    }
  }
}
.branchestab {
  margin-top: 60px;
  .nav-tabs {
    border-bottom: none;
  }
  .nav-tabs .nav-link:hover {
    border: none I !important;
  }
  .nav-tabs .nav-link.active {
    color: #fff !important;
    background: linear-gradient(
      54.88deg,
      #856402 -15.56%,
      #e0a700 46.22%,
      #856402 103.33%
    );
    border-color: none;
  }
  .nav-tabs .nav-link {
    border-color: #856402 !important;
    border-radius: 80px;
    color: #856402 !important;
    margin: 0 15px 0 15px;
    padding: 10px 30px;
  }
  &__dining {
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    &--desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #4a4a4a;
      width: 80%;
      margin: 60px auto;
    }
    &--left {
      box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
      ul {
        list-style: disc;
        padding-left: 46px;
        padding-bottom: 20px;
        li {
          padding: 5px 0;
          color: #4a4a4a;
          font-size: 16px;
        }
      }
      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 29px;
        color: #0d0d0d;
        border-bottom: 0.8px solid #c2c2c2;
        padding: 11px 30px;
      }
    }
    &--right {
      box-shadow: 0px 0px 16px rgba(211, 211, 211, 0.4);
      ul {
        column-count: 3;
        padding-left: 46px;
        padding-bottom: 20px;
        @include responsive-for(md) {
          column-count: 2;
        }
        li {
          list-style: disc;
          padding: 5px 0;
          color: #4a4a4a;
          font-size: 16px;
        }
      }
      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 29px;
        color: #0d0d0d;
        border-bottom: 0.8px solid #c2c2c2;
        padding: 11px 30px;
      }
    }
  }
  &__banquethall {
    .component_bg {
      background-color: #fff !important;
    }
  }
  &__reviews {
    width: 97%;
    background-color: #f3f3f3;
    padding: 50px 70px;
    position: relative;
    &--icon {
      color: #d0d0d0;
      font-size: 25px;
      position: absolute;
      left: 20px;
      top: 35px;
    }
    &--review {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #4a4a4a;
    }
    h5 {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: #0d0d0d;
      margin-top: 30px;
      margin-bottom: 0;
    }
    &--post {
      text-align: center;
      font-weight: 500;
    }
  }
  &__photo {
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
  }
}
.roomdesc {
  img {
    width: 100%;
    height: 515px;
    object-fit: cover;
    @include responsive-for(md) {
      height: 300px;
    }
  }
}
@media (max-width: 768px) {
  .nav-item {
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .slick-prev {
    left: 1px !important;
  }
  .slick-next {
    right: 5px !important;
  }
}
