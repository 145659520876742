.bookingWrapper {
  background: #f6f6f6;
  width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  padding: 1rem;

  h5 {
    margin-bottom: 1rem;
  }

  &-roomDetails {
    background: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    &-info {
      font-size: 14px;
      color: rgb(126, 126, 126);
      line-height: 170%;
      .roomSelectBox {
        width: 50%;
        label {
          font-size: 16px;
          color: color(text-dark);
          margin-top: 1rem;
        }
      }
    }
  }

  &-customerDetials {
    margin-top: 2rem;
    background: #fff;
    padding: 0.5rem;
    border-radius: 4px;
  }
  &-billDetails {
    margin-top: 2rem;
    background: #fff;
    padding: 0.5rem;
    border-radius: 4px;
  }
}

.paymentWrapper {
  background: #f6f6f6;
  width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  padding: 1rem;
  h5 {
    margin-bottom: 1rem;
  }

  .paymentBox {
    margin-top: 1rem;
    padding: 0;
    display: flex;
    &-items {
      background: #fff;
      padding: 1rem;
      margin-right: 1rem;
      border-radius: 8px;
    }

    .activePayment {
      border: 2px solid color(primary, base);
    }
  }
}

.bookingSuccess {
  text-align: center;
  &-icon {
    font-size: 4rem;
    color: green;
  }
  &-message {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 500;
  }
  &-desc {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 400;
  }
}
